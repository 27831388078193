import React, { Component } from 'react';

import ReactDOM from 'react-dom';

import './vendor/fontawesome-free/css/all.css';
import './css/style.css';
import './css/sb-admin-2.min.css';

import { Helmet } from "react-helmet";

import {OverlayTrigger,Tooltip,Button} from "react-bootstrap";

import dashboardicon from './img/sidebar/dashboard.svg';
import propertiesicon from './img/sidebar/properties-colored.png';
import profilesicon from './img/sidebar/profiles-colored.png';
import alerticon from './img/sidebar/alert.svg';
import searchicon from './img/sidebar/search-colored.png';
import insighticon from './img/sidebar/insight.svg';
import subscriptionsicon from './img/sidebar/subscriptions.png';
import accounticon from './img/sidebar/account-colored.png';
import loginsicon from './img/sidebar/logins.png';
import metricsicon from './img/sidebar/metrics-colored.png';
import mapicon from './img/sidebar/map-colored.png';
import settingsicon from './img/sidebar/settings-colored.png';

import logo from './img/sidebar/logo.png';

import Dashboard from '../components/Dashboard';
import Properties from '../components/Properties'; 
import PropertyView from '../components/PropertyView'; 
import PropertiesMap from '../components/PropertiesMap'; 
import Profiles from '../components/Profiles';
import Alerts from '../components/Alerts';
import Search from '../components/Search';
import Charts from '../components/Charts';
import Account from '../components/Account';
import Settings from '../components/Settings';

import Dialog from 'react-bootstrap-dialog'

//import BLO from '../utilities/BLO';
import * as BLO from '../utilities/BLO';

import InvestorTypeDialog from '../dialogs/InvestorTypeDialog';

import FinancingPreferenceDialog from '../dialogs/FinancingPreferenceDialog';

import { authMiddleWare } from '../utilities/Auth';

import { getElapsedDays, getElapsedHours } from '../utilities/AppUtility';

import { numberOfTrialDays } from '../utilities/Constants';

import axios from 'axios';

class Home extends Component {

    state = {
        currentPage : 'dashboard',
        investmentType : '',
        sessionUserName:'',
        profiles:[],
        properties:[],
        alerts:[],
        profileId:null,
        profile:null,
        settings:null,
        user:null,
        displayScreen:false,
        initialViewSet:false,
        propertyCounts: {
            selectedInvestmentPropertyCount:0,
            primaryHomePropertyCount:0,
            rentalPropertyCount:0,
            flippingPropertyCount:0
        }            
    };

    constructor(props) {
        super(props);

        this.setAsActive = this.setAsActive.bind(this);

        this.investorTypeDialogRef = React.createRef();

        this.financingPreferenceDialogRef = React.createRef();
    }

    componentWillMount = () => {

        this.firstTimeLoginActions();
        
        try
        {
            //Check session expired
            if (localStorage.getItem('SessionExpired') == "Y")
            {
                localStorage.setItem('UsersStale','Y') 
                window.location.href = '/login';
            }

            console.log("About to get logged in user.");
    
            const sessionUserName = localStorage.getItem('SessionUserName');
            
            //console.log(sessionUser);

            this.setState({ 
                sessionUserName: sessionUserName
            });	

                //Load User
                if (this.state.user == null || localStorage.getItem('UsersStale') == "Y")
                {
                    authMiddleWare(this?.props?.history);
                    const authToken = localStorage.getItem('AuthToken');
                    const refreshToken = localStorage.getItem('RefreshToken');
                    axios.defaults.headers.common = { Authorization: `${authToken}`,RefreshToken:refreshToken };
                    axios
                    .get('/api/user')
                    .then((response) => {
                        // console.log(response.data);
    
                        if (response.headers["authtoken"] != null && response.headers["authtoken"] != "" && response.headers["refreshtoken"] != null && response.headers["refreshtoken"] !="")
                        {
                            localStorage.setItem('AuthToken', `Bearer ${response.headers["authtoken"]}`); //We need to set them again on each request in case they got refresh to keep user logged in all the time
                            localStorage.setItem('RefreshToken', response.headers["refreshtoken"]); //We need to set them again on each request in case they got refresh to keep user logged in all the time
                        }
        
                        const user = response.data

                        this.setState({ 
                            user: user
                        });	

                        this.checkRedirects(user);
                        this.checkInvestorType(user);
                        this.enforceAlertSetup();      

                        localStorage.setItem('UsersStale',"N");
                        
                    })
                    .catch((error) => {
                        console.log('Error in getting user record:'+error);
    

                        this.setState({ 
                            user: null
                        });	
                        localStorage.setItem('UsersStale',"Y");
                
                        });


                } else {
                    this.checkRedirects(this.state.user);
                    this.checkInvestorType(this.state.user);
                    this.enforceAlertSetup();      
                }
           
                this.setState({ 
                    
                    displayScreen: true

                }, () => { 
            
                    //Load Profiles
                    if (this.state.profiles.length == 0 || this.state.profile == null || localStorage.getItem('ProfileListStale') == "Y")
                    {
                        authMiddleWare(this?.props?.history);
                        const authToken = localStorage.getItem('AuthToken');
                        const refreshToken = localStorage.getItem('RefreshToken');
                        axios.defaults.headers.common = { Authorization: `${authToken}`,RefreshToken:refreshToken };
                        axios
                            .get('/api/profiles')
                            .then((response) => {

                                if (response.headers["authtoken"] != null && response.headers["authtoken"] != "" && response.headers["refreshtoken"] != null && response.headers["refreshtoken"] !="")
                                {
                                    localStorage.setItem('AuthToken', `Bearer ${response.headers["authtoken"]}`); //We need to set them again on each request in case they got refresh to keep user logged in all the time
                                    localStorage.setItem('RefreshToken', response.headers["refreshtoken"]); //We need to set them again on each request in case they got refresh to keep user logged in all the time
                                }
                
                                this.setState({
                                    profiles: response.data
                                }, () => {
                                    
                                this.preselectProfileSelect(response.data);
                                
                                this.chooseAppropriateTrialProfile("componentWillMount");
                                this.chooseAppropriateInvestmentType();

                                });
                                localStorage.setItem('ProfileListStale',"N");
                            })
                            .catch((error) => {
                                console.log(error);
                                if (error && error.response && error.response.status && error.response.status === 403) {
                                    localStorage.setItem('SessionExpired',"Y");
                                    //window.location.reload();
                                    window.location.href = '/login';
                                }
                            });
                        }

                        //console.log("this.state.settings:"+this.state.settings);
                        //console.log("localStorage.getItem('SettingsStale'):"+localStorage.getItem('SettingsStale'));

                        //Load Settings
                        if (this.state.settings == null || localStorage.getItem('SettingsStale') == "Y")
                        {
                            this.loadSettings();
                        }

                });


        } catch(err)
        {
            console.log("Error getting logged in user:"+err);
        
        }
          
    }

    componentDidUpdate = (user) => {
    
        
        let investmentType = "";

        // Preset investment type based on profile only if user didn't select one before
        if (localStorage.getItem('SelectedInvestmentType') == null || localStorage.getItem('SelectedInvestmentType')==='') 
        {
           
            if (this.state.profile != null && !this.state.profile.hasOwnProperty("investorType"))
            {
                this.state.profile.investorType ="Primary Home";
            }

            if (this.state.profile != null && this.state.profile.hasOwnProperty("investorType")) 
            {
                investmentType = this.state.investmentType;
                
                var defaultOption = null;

                var investmentTypeOptions = document.getElementById("investmentTypeSelect").options;
                
                for (let i = 0; i < investmentTypeOptions.length; i++) {
                
                    defaultOption = investmentTypeOptions[i];
                    
                    if (defaultOption.value.startsWith("Primary"))
                    {
                        break;
                    }
                    
                }

                switch (this.state.profile.investorType) {
                    case "homebuyer":
                        investmentType = "Primary Home";
                    break;
                    case "rental":
                        investmentType = "Rental Property";
                    break;
                    case "flipper":
                        investmentType = "Flipping Properties";
                    break;
                }

                for (let i = 0; i < investmentTypeOptions.length; i++) {
                    //console.log(investmentTypeOptions[i].text);
                    //console.log(investmentTypeOptions[i].value);
            
                    if (investmentTypeOptions[i].value == investmentType)
                    {
                        investmentTypeOptions[i].selected = true;
                        this.setState({investmentType: investmentTypeOptions[i].value});  
                        localStorage.setItem('SelectedInvestmentType',investmentTypeOptions[i].value);
                        break;
        
                    }
                }

                if (investmentType == null || investmentType == '' )
                {
                    defaultOption.selected = true;
                    this.setState({investmentType: defaultOption.value}); 
                    localStorage.setItem('SelectedInvestmentType',defaultOption.value); 
        
                }
    
            }

        }

        if (this.state.initialViewSet == false)
        {
           //ALSO SET DEFAULT VIEW BASED ON USER TYPE
            if (localStorage.getItem('CurrentPage') == null || localStorage.getItem('CurrentPage') === 'null' || localStorage.getItem('CurrentPage') ==='')
            {
                    //Set based on user investor Type
                    if (this.state.user != null && this.state.user.investorType != null)
                    {
                        let currentPage = "dashboard";     
                        switch (this.state.user.investorType) {

                            case "rental": 
                                currentPage = "dashboard"; 
                                break;
                            case "flipper": 
                                currentPage = "dashboard"; 
                                break;
                            case "homebuyer": 
                                currentPage = "properties"; 
                                break;
                            case "realtor": 
                                currentPage = "properties"; 
                                break;
                        }

                        try{
                            var selectedElement = document.getElementById(currentPage); //This is for the left menu selection
                            this.setAsActive(selectedElement, currentPage); //This is for the left menu selection
                        } catch(error)
                        {
        
                        }
                        this.setState({ 
                            currentPage: currentPage,
                            initialViewSet: true 
                        }); //This is for the actual page selection
        
                    }
        
            }
        }

    }


    enforceAlertSetup = () => {
    
        //console.log("Has investorType:"+this.state.user.hasOwnProperty("investorType"));
        //console.log("this.state.investorType:"+this.state.investorType);

        if (this.state.user.hasOwnProperty("investorType") && this.state.user.investorType !=='')
        {
            if (this.state.alerts == null || this.state.alerts.length ==0)
            {
                authMiddleWare(this?.props?.history);
                const authToken = localStorage.getItem('AuthToken');
                const refreshToken = localStorage.getItem('RefreshToken');
                axios.defaults.headers.common = { Authorization: `${authToken}`,RefreshToken:refreshToken };

                axios
                .get('/api/alerts')
                .then((response) => {

                    if (response.headers["authtoken"] != null && response.headers["authtoken"] != "" && response.headers["refreshtoken"] != null && response.headers["refreshtoken"] !="")
                    {
                        localStorage.setItem('AuthToken', `Bearer ${response.headers["authtoken"]}`); //We need to set them again on each request in case they got refresh to keep user logged in all the time
                        localStorage.setItem('RefreshToken', response.headers["refreshtoken"]); //We need to set them again on each request in case they got refresh to keep user logged in all the time
                    }

                    // console.log("Before refresh token:")
                    // console.log("---------------------")
                    // console.log(refreshToken);
                    // console.log("")
                    // console.log("Return Refresh Token:")
                    // console.log("---------------------")
                    // console.log(response.headers["refreshtoken"]);

                    this.setState({
                        alerts: response.data,
                        
                    }, () => {

                        if (response.data ==null || response.data.length==0) 
                        {         
                            this.noAlertDialogMessage.show({
                                title: 'No Advanced Searches Configured',
                                body: "It appears that you haven't set up any Advanced Searches yet. Please configure at least one Advanced Search so that we can locate properties on your behalf.",
                                actions: [
                                Dialog.OKAction(() => {
                                //console.log('OK was clicked!')
                                
                                this.setState({ currentPage: 'alerts' });
                                localStorage.setItem('CurrentPage','alerts');
                    
                                var profileOptions = document.getElementById("profileSelect").options;
                                var defaultOption = this.preselectTheDefautProfileDropdown("Default",profileOptions);

                                document.getElementById("profileSelect").parentElement.style.display = "";
                    
                                //We don't need the investment type dropdowns for this screen because it is a general screen
                                document.getElementById("investmentTypeSelect").parentElement.style.display = "none";
                                document.getElementById("propertyCounts").style.display = "none";
                                                
                                })
                                ],
                                bsSize: 'small',
                                onHide: (dialog) => {
                                dialog.hide()
                                
                                this.setState({ currentPage: 'alerts' });
                                localStorage.setItem('CurrentPage','alerts');
                    
                                var profileOptions = document.getElementById("profileSelect").options;
                                var defaultOption = this.preselectTheDefautProfileDropdown("Default",profileOptions);

                                document.getElementById("profileSelect").parentElement.style.display = "";
                    
                                //We don't need the investment type dropdowns for this screen because it is a general screen
                                document.getElementById("investmentTypeSelect").parentElement.style.display = "none";
                                document.getElementById("propertyCounts").style.display = "none";  

                                }
                            });   
                        }
                    });
                })
                .catch((error) => {
                    //alert(error);
                    console.log(error);
                });
            }
        }
    }

    chooseAppropriateInvestmentType()
    {
        //console.log("Inside chooseAppropriateInvestmentType()");
        
        const demoProfileId = this.getProfileDropdownID("Demo");
       
        authMiddleWare(this?.props?.history);
        const authToken = localStorage.getItem('AuthToken');
        const refreshToken = localStorage.getItem('RefreshToken');
        axios.defaults.headers.common = { Authorization: `${authToken}`,RefreshToken:refreshToken };
        axios
            .get('/api/properties')
            .then((response) => {

                let rentalCount = 0;
                let flippingCount = 0;
                let primaryCount = 0;

                let totalCount = 0;

                let properties = response.data;
     
                for (let property of properties)
                {
                    if (property.profileId != demoProfileId)
                    {
                        totalCount = totalCount + 1;
                    }

                    if (property.investmentType == "Rental Property" && property.profileId != demoProfileId)
                    {
                        rentalCount = rentalCount + 1;

                    } else if (property.investmentType == "Primary Home" && property.profileId != demoProfileId)
                    {
                        primaryCount = primaryCount + 1;

                    }else if (property.investmentType == "Flipping Property" && property.profileId != demoProfileId)
                    {
                        flippingCount = flippingCount + 1;
                    }
                }

                if (totalCount > 0)
                {
                    //console.log("rentalCount:"+rentalCount);
                    //console.log("primaryCount:"+primaryCount);
                    //console.log("flippingCount:"+flippingCount);

                    if (primaryCount == 0 && flippingCount ==0 && rentalCount !=0)
                    {
                        //console.log("primaryCount == 0 && flippingCount ==0 && rentalCount !=0");

                        this.preselectTheDefautInvestmentTypeDropdown("Rental Property");

                    } else if (primaryCount == 0 && flippingCount !=0 && rentalCount ==0)
                    {
                        //console.log("primaryCount == 0 && flippingCount !=0 && rentalCount ==0");
                        
                        this.preselectTheDefautInvestmentTypeDropdown("Flipping Property");

                    } else if (primaryCount == 0)
                    {
                        //console.log("primaryCount == 0");

                        this.preselectTheDefautInvestmentTypeDropdown("Flipping Property");
                    }
                }
            })
            .catch((error) => {
                console.log(error);
            
            });
    
    }

    chooseAppropriateTrialProfile(originSource)
    {
        /*
            This method is to choose the right profile for trial accounts.

            Basically, we to selected the Default profile only if it has properties, otherwise we want to select the Demo profile

        */
            const demoProfileId = this.getProfileDropdownID("Demo");
            const defaultProfileId = this.getProfileDropdownID("Default");

            //alert("demoProfileId:"+demoProfileId);
            //alert("defaultProfileId:"+defaultProfileId);
           
        if (this.state.user.planType ==="trial")
        {
            authMiddleWare(this?.props?.history);
            const authToken = localStorage.getItem('AuthToken');
            const refreshToken = localStorage.getItem('RefreshToken');
            axios.defaults.headers.common = { Authorization: `${authToken}`,RefreshToken:refreshToken };
            axios
                .get('/api/properties')
                .then((response) => {

                    if (response.headers["authtoken"] != null && response.headers["authtoken"] != "" && response.headers["refreshtoken"] != null && response.headers["refreshtoken"] !="")
                    {
                        localStorage.setItem('AuthToken', `Bearer ${response.headers["authtoken"]}`); //We need to set them again on each request in case they got refresh to keep user logged in all the time
                        localStorage.setItem('RefreshToken', response.headers["refreshtoken"]); //We need to set them again on each request in case they got refresh to keep user logged in all the time
                    }

                    // console.log("PROP-Before refresh token:")
                    // console.log("---------------------")
                    // console.log(refreshToken);
                    // console.log("")
                    // console.log("PROP-Return Refresh Token:")
                    // console.log("---------------------")
                    // console.log(response.headers["refreshtoken"]);

                    
                    let properties = response.data;
                    
                    /*
                    this.setState({
                        properties: properties
                    });
                    */

                    let nonDemoPropertyCount = 0;
                    for (let property of properties)
                    {
                        if (property.profileId != demoProfileId)
                        {
                            nonDemoPropertyCount = nonDemoPropertyCount + 1;
                        }
                    }

                    //alert(nonDemoPropertyCount);

                    var profileOptions = document.getElementById("profileSelect").options;
                        
                    if (nonDemoPropertyCount > 0)
                    {
                        var defaultOption = this.preselectTheDefautProfileDropdown("Default",profileOptions);
                    }else
                    {
                        var defaultOption = this.preselectTheDefautProfileDropdown("Demo",profileOptions);
                    }

                })
                .catch((error) => {
                    console.log(error);
              
                });
     
                
                //console.log("originSource:"+originSource);
                if (originSource ==="alertCreate")
                {
                    //console.log("Loading properties");
                    //this.setState({ currentPage: 'properties' });
                    localStorage.setItem('CurrentPage','properties');
                    window.location.reload();
                }
     
            }
            
    }

    checkInvestorType = (user) => {

        //alert(user.hasOwnProperty("investorType"));

        if (!user.hasOwnProperty("investorType") || user.investorType ==='')
        {
            var dialog = this.investorTypeDialogRef.current;

            dialog.PopulateDialog(user,this);

        } else if (user.investorType ==="flipper" || user.investorType ==="rental") 
        {
            if (!user.hasOwnProperty("financingPreference") || user.financingPreference ==='')
            {
                var dialog = this.financingPreferenceDialogRef.current;

                dialog.PopulateDialog(user,this);
            }
        }

    }

    checkRedirects = (user) => {

		let nextPage = "";

		if (user.emailHasBeenConfirmed ==='N')
		{
			nextPage = "/signup/email-confirmation";
		} else
		{
			if (user.planType !="trial")
			{
				if (user.termsAndConditionsAccepted ==="N")
				{
					nextPage = "/signup/terms-and-conditions";

				} else if (user.stripeSubscriptionId ==="")
				{
					nextPage = "/signup/billing-information";
				} 

			} 
		}

        if (nextPage === "" && user.planType ==="trial")
        {
            this.hasTrialVersionExpired(user)
        }

        if (nextPage != "")
        {
            this.props.history.push(nextPage);
        }

	}



    checkUserSubscriptionStatus = () => {

        if (this.state.user != null)
        {
            const lastCheckedDateTime = localStorage.getItem('LastSubscriptionCheckDateTime');
            
            console.log("lastCheckedDateTime:");
            console.log(lastCheckedDateTime);

            if (lastCheckedDateTime == null || lastCheckedDateTime == "" || this.hasBeen24HoursOfLastCheck(lastCheckedDateTime))
            {
                console.log("CHECKING SUBSCRIPTION STATUS");

                
                localStorage.setItem('SubscriptionStatus','');

                if (this.state.user.stripeSubscriptionId != "" && this.state.user.stripeSubscriptionId != null)
                {
                    //CHECK SUBSCRIPTION STATUS
                    const authToken = localStorage.getItem('AuthToken');
                    const refreshToken = localStorage.getItem('RefreshToken');
                    axios.defaults.headers.common = { Authorization: `${authToken}`,RefreshToken:refreshToken };
                    axios
                        .post('/api/subscription', {
                            subscriptionId: this.state.user.stripeSubscriptionId
                        })
                        .then((response) => {
                            //alert(response.data);
                            
                            if (response.headers["authtoken"] != null && response.headers["authtoken"] != "" && response.headers["refreshtoken"] != null && response.headers["refreshtoken"] !="")
                            {
                                localStorage.setItem('AuthToken', `Bearer ${response.headers["authtoken"]}`); //We need to set them again on each request in case they got refresh to keep user logged in all the time
                                localStorage.setItem('RefreshToken', response.headers["refreshtoken"]); //We need to set them again on each request in case they got refresh to keep user logged in all the time
                            }
            
                            localStorage.setItem('LastSubscriptionCheckDateTime',(new Date()).toISOString());

                            const subscriptionStatus = response.data.status;

                            localStorage.setItem('SubscriptionStatus',subscriptionStatus);

                            this.checkActiveSubscription();

                            if (this.state.user.planType ==="trial")
                            {
                                this.hasTrialVersionExpired(this.state.user)

                                localStorage.setItem('SubscriptionStatus','active'); // If the trial version has not expired then the subscriptionStatus='active' 
                            }

                            if (response.data.status == null || response.data.status === "")
                            {
                                localStorage.setItem('SubscriptionStatus','active');
                            }

                            this.updateAccountStatus(this.state.user.accountNumber,subscriptionStatus);

                        })
                        .catch((error) => {
                            
                            if (this.state.user.planType ==="trial")
                            {
                                this.hasTrialVersionExpired(this.state.user)
                                localStorage.setItem('SubscriptionStatus','active'); // If the trial version has not expired then the subscriptionStatus='active' 
                            } else
                            {
                                localStorage.setItem('SubscriptionStatus','active');  // if so let them in, we'll catch them the next time
                            }
                            console.log(error);
                        });

                 } else
                 {
                    if (this.state.user.planType ==="trial")
                    {
                        this.hasTrialVersionExpired(this.state.user)
                        localStorage.setItem('SubscriptionStatus','active'); // If the trial version has not expired then the subscriptionStatus='active' 
                    } else
                    {
                        localStorage.setItem('SubscriptionStatus','active');  // if so let them in, we'll catch them the next time
                    }
                 }

                }
        }

    }

    updateAccountStatus(accountNumber,accountStatus) {

		try {

			const authToken = localStorage.getItem('AuthToken');
			const refreshToken = localStorage.getItem('RefreshToken');
			axios.defaults.headers.common = { Authorization: `${authToken}`,RefreshToken:refreshToken };
			axios
				.post('/api/updateUserAccountStatus', {
					accountNumber:accountNumber,
					accountStatus:accountStatus
				})
				.then((response) => {
					//alert(response.data);
					if (response.headers["authtoken"] != null && response.headers["authtoken"] != "" && response.headers["refreshtoken"] != null && response.headers["refreshtoken"] !="")
					{
						localStorage.setItem('AuthToken', `Bearer ${response.headers["authtoken"]}`); //We need to set them again on each request in case they got refresh to keep user logged in all the time
						localStorage.setItem('RefreshToken', response.headers["refreshtoken"]); //We need to set them again on each request in case they got refresh to keep user logged in all the time
					}
	
				})
				.catch((error) => {
					
					console.log(error);
				});
				
		} catch(error)
		{
			console.log(error);
		}

	}



    investorTypeDialogDidComplete = (investorType) => {

        if (investorType === "flipper" || investorType === "rental")
        {
            var dialog = this.financingPreferenceDialogRef.current;

            dialog.PopulateDialog(this.state.user,this);
        }

        this.state.user.investorType = investorType; 
    }

    hasBeen24HoursOfLastCheck = (lastCheckedDateTime) => {
     
        let hasBeen24Hours = false;

        if (lastCheckedDateTime != null && lastCheckedDateTime !="")
        {
            const elapsedHours = getElapsedHours(lastCheckedDateTime);

            console.log("elapsedHours:");
            console.log(elapsedHours);

            if (elapsedHours >= 24)
            {
                hasBeen24Hours = true;
            }
        }

        return hasBeen24Hours;
    }

    hasTrialVersionExpired = (user) => {

        let expired = false;

        const numberElapsedDays = getElapsedDays(user);

        if (numberElapsedDays > numberOfTrialDays)
        {
            expired = true;

            this.updateAccountStatus(user.accountNumber,"expired");

            this.dialogMessage.show({
                title: 'Plan Limit Exceeded',
                body: "Your trial version has expired, please UPDATE to a paid subscription plan.",
                actions: [
                  Dialog.OKAction(() => {
                    //console.log('OK was clicked!')
                    this.props.history.push("/pricing");               
                  })
                ],
                bsSize: 'small',
                onHide: (dialog) => {
                  dialog.hide()
                  this.props.history.push("/pricing");  
                }
              });

        }

        return expired;
    }


    loadSettings()
    {
        try
        {
            //console.log("About to call /api/settings");

            authMiddleWare(this?.props?.history);
            const authToken = localStorage.getItem('AuthToken');
            const refreshToken = localStorage.getItem('RefreshToken');
            axios.defaults.headers.common = { Authorization: `${authToken}`,RefreshToken:refreshToken };
            axios
                .get('/api/settings')
                .then((response) => {

                    if (response.headers["authtoken"] != null && response.headers["authtoken"] != "" && response.headers["refreshtoken"] != null && response.headers["refreshtoken"] !="")
                    {
                        localStorage.setItem('AuthToken', `Bearer ${response.headers["authtoken"]}`); //We need to set them again on each request in case they got refresh to keep user logged in all the time
                        localStorage.setItem('RefreshToken', response.headers["refreshtoken"]); //We need to set them again on each request in case they got refresh to keep user logged in all the time
                    }
    
                    //console.log("Account Settings:");
                    //console.log(JSON.stringify(response.data));

                    const settings = response.data;
                    const benchmarkSettings = BLO.getIndicatorBenchmarkSettings(settings);
                    settings.benchmarkSettings = benchmarkSettings;

                    //console.log("benchmarkSettings:");
                    //console.log(JSON.stringify(benchmarkSettings));

                    this.setState({
                        settings: settings
                    }, () => {
                    
                        localStorage.setItem('SettingsStale',"N");
                    });
                    
                })
                .catch((error) => {
                    console.log(error);
                    if (error && error.response && error.response.status && error.response.status === 403) {
                        localStorage.setItem('SessionExpired',"Y");
                        //window.location.reload();
                        window.location.href = '/login';
                    }
                });
            } catch(error)
            {
              console.log(error);   
            }

    }


    updateAccountStatus(accountNumber,accountStatus) {

		try {

			authMiddleWare(this?.props?.history);
            const authToken = localStorage.getItem('AuthToken');
            const refreshToken = localStorage.getItem('RefreshToken');
            axios.defaults.headers.common = { Authorization: `${authToken}`,RefreshToken:refreshToken };
			axios
				.post('/api/updateUserAccountStatus', {
					accountNumber:accountNumber,
					accountStatus:accountStatus
				})
				.then((response) => {
					//alert(response.data);

                    if (response.headers["authtoken"] != null && response.headers["authtoken"] != "" && response.headers["refreshtoken"] != null && response.headers["refreshtoken"] !="")
                    {
                        localStorage.setItem('AuthToken', `Bearer ${response.headers["authtoken"]}`); //We need to set them again on each request in case they got refresh to keep user logged in all the time
                        localStorage.setItem('RefreshToken', response.headers["refreshtoken"]); //We need to set them again on each request in case they got refresh to keep user logged in all the time
                    }
    
				})
				.catch((error) => {
					
					console.log(error);
				});
				
		} catch(error)
		{
			console.log(error);
		}

	}


    preselectTheDefautProfileDropdown = (selectIptionText,profileOptions) => {

    let defaultOption = null;

    for (let i = 0; i < profileOptions.length; i++) {
        //console.log(profileOptions[i].text);
        //console.log(profileOptions[i].value);

            if (profileOptions[i].text.includes(selectIptionText))
            {
                defaultOption = profileOptions[i];

                defaultOption.selected = true;
                const profile = this.state?.profiles?.filter(profile => profile.id == defaultOption.value)[0];
                this.setState({
                        profileId: defaultOption.value,
                        profile: profile
                    });  

                localStorage.setItem('SelectedProfileId',defaultOption.value);

                break;
            }
        }

        return defaultOption
    }

    
    preselectTheDefautInvestmentTypeDropdown = (selectIptionText) => {

        //console.log("Inside preselectTheDefautInvestmentTypeDropdown:"+selectIptionText);

        let defaultOption = null;
    
        var investmentTypeOptions = document.getElementById("investmentTypeSelect").options;

        for (let i = 0; i < investmentTypeOptions.length; i++) {
            //console.log("investmentTypeOptions[i].text:"+investmentTypeOptions[i].text);
            //console.log("investmentTypeOptions[i].value:"+investmentTypeOptions[i].value);
    
                if (investmentTypeOptions[i].value.includes(selectIptionText))
                {
                    //console.log("Found investment type option:"+investmentTypeOptions[i]);

                    defaultOption = investmentTypeOptions[i];
    
                    defaultOption.selected = true;
    
                    this.setState({
                        investmentType: selectIptionText
                    });  
                    localStorage.setItem('SelectedInvestmentType',defaultOption.value);
    
                    break;
                }
            }
    
            return defaultOption
        }

    getProfileDropdownID = (selectIptionText) => {

        var id = "";    
        var profileOptions = document.getElementById("profileSelect").options;

        let defaultOption = null;
    
        for (let i = 0; i < profileOptions.length; i++) {
            //console.log(profileOptions[i].text);
            //console.log(profileOptions[i].value);
    
                if (profileOptions[i].text.includes(selectIptionText))
                {
                    defaultOption = profileOptions[i];
                    
                    id = defaultOption.value;
                    break;
                }
            }
    
            return id
        }
    
    
    executeSettingDefaultValues = () => {

        let itWasFirstLogin = false;

            //1. Set default page
            localStorage.setItem('CurrentPage','dashboard');

            //2. Set default investment type
            const investmentType = "Primary Home";     
            this.setState({investmentType: investmentType});  
            localStorage.setItem('SelectedInvestmentType',investmentType);

            //3. Set default profile 
            try {
                var profileOptions = document.getElementById("profileSelect").options;

                var defaultOption = null;
                //Get demo option

                defaultOption = this.preselectTheDefautProfileDropdown("Demo",profileOptions);

                    //If value is still null then set the default profile
                    if (defaultOption == null)
                    {
                        //Get default option
                        defaultOption = this.preselectTheDefautProfileDropdown("Default",profileOptions);
                    }

            } catch(error)
            {
                itWasFirstLogin = true;
                console.log(error);
            }
            
         
            return itWasFirstLogin;
    }

    firstTimeLoginActions = () => {

        //First time logging in
        let itWasFirstLogin = false;

        try
        {
            if (!this.state.user.hasOwnProperty("investorType") || this.state.user.investorType ==='')
            {
                    itWasFirstLogin = true;

                    itWasFirstLogin = this.executeSettingDefaultValues();
                    
            }
        
        } catch(error)
        {
            //itWasFirstLogin = true;
            //this.executeSettingDefaultValues();
            console.log(error);
        }

        if (itWasFirstLogin == false)
        {
            this.preselectMissingRequiredFields();
        }
        
    }
 
    preselectMissingRequiredFields = () => {

        //First time logging in
        try
        {
                this.executeSettingDefaultValues();

                this.setState({investmentType: localStorage.getItem('SelectedInvestmentType')});
                this.setState({profileId: localStorage.getItem('SelectedProfileId')});

        } catch(error)
        {
            this.executeSettingDefaultValues();
            console.log(error);
        }
    }

    checkActiveSubscription = () => {

        //First time logging in
        if (!this.state.user.hasOwnProperty("investorType") || this.state.user.investorType ==='')
        {
            localStorage.setItem('SubscriptionStatus','active');
        }

        if (localStorage.getItem('SubscriptionStatus') !== "" && localStorage.getItem('SubscriptionStatus') !== "active")
        {

            this.dialogMessage.show({
                title: 'Subscription Status',
                body: "Your subscription status is not ACTIVE, please update your payment method or upgrade your subscription.",
                actions: [
                Dialog.OKAction(() => {
                    //console.log('OK was clicked!')
                    this.setState({ currentPage: 'account' });
                    localStorage.setItem('CurrentPage','account');              
                })
                ],
                bsSize: 'small',
                onHide: (dialog) => {
                dialog.hide()
                this.setState({ currentPage: 'account' });
                localStorage.setItem('CurrentPage','account'); 
                }
            });
        } 
    }

    componentDidMount = () => {
     
        try
        {
            //Check subscription not active
            this.checkActiveSubscription();
                
            //Normal logic          
            if (localStorage.getItem('CurrentPage') != null && localStorage.getItem('CurrentPage') !== 'null' && localStorage.getItem('CurrentPage')!='')
            {
                const currentPage = localStorage.getItem('CurrentPage');
                try{
                    var selectedElement = document.getElementById(currentPage); //This is for the left menu selection
                   this.setAsActive(selectedElement, currentPage); //This is for the left menu selection
                } catch(error)
                {

                }
                this.setState({ currentPage: currentPage,
                                initialViewSet: true }); //This is for the actual page selection
                //localStorage.setItem('CurrentPage','');

                if (currentPage == "profiles" || currentPage == "settings")
                {
                    
                    //We don't need the investment type or profile dropdowns for this screen because it is a general screen
                    document.getElementById("profileSelect").parentElement.style.display = "none";
                    document.getElementById("investmentTypeSelect").parentElement.style.display = "none";
                    document.getElementById("propertyCounts").style.display = "none";
                } else 
                {
                    if (currentPage == "alerts")
                    {
                        document.getElementById("profileSelect").parentElement.style.display = "";

                        //We don't need the investment type dropdown for this screen because it is a general screen
                        document.getElementById("investmentTypeSelect").parentElement.style.display = "none";
                        document.getElementById("propertyCounts").style.display = "none";

                    } else
                    {
                        document.getElementById("profileSelect").parentElement.style.display = "";
                        document.getElementById("investmentTypeSelect").parentElement.style.display = "";
                        document.getElementById("propertyCounts").style.display = "";
                    }
    
                }
            } 
            
            this.preselectInvestmentTypeSelect();
        
        } catch(error)
        {
            console.log(error);
        }
    }

    goToUpGrade = (event) => {
    
        if (this.state.user.planName !=='')
        {
            //this.props.history.push('/signup/terms-and-conditions');
            window.location.href ="/signup/terms-and-conditions";

        }else
        {
            //this.state.props.history.push('/pricing');
            window.location.href ="/pricing";
        }
    }

    loadDashboardPage = (event) => {

            event.preventDefault();
            if (localStorage.getItem('SubscriptionStatus') === "active")
            {
                let viewModePage = 'dashboard';

                if (localStorage.getItem('LastViewMode') != null && localStorage.getItem('LastViewMode') !== 'null' && localStorage.getItem('LastViewMode') !=='')
                {
                    viewModePage = localStorage.getItem('LastViewMode');
                }

                this.setAsActive(event.currentTarget, viewModePage);
                this.setState({ currentPage: viewModePage });
                localStorage.setItem('CurrentPage',viewModePage);

                document.getElementById("profileSelect").parentElement.style.display = "";
                document.getElementById("investmentTypeSelect").parentElement.style.display = "";
                document.getElementById("propertyCounts").style.display = "";

                this.enforceAlertSetup(); 
            }
    };

    loadPropertiesPage = (event) => {

            event.preventDefault();

            if (localStorage.getItem('SubscriptionStatus') === "active")
            {
                this.setAsActive(event.currentTarget, 'properties');
                this.setState({ currentPage: 'properties' });
                localStorage.setItem('CurrentPage','properties');

                document.getElementById("profileSelect").parentElement.style.display = "";
                document.getElementById("investmentTypeSelect").parentElement.style.display = "";
                document.getElementById("propertyCounts").style.display = "";

                this.enforceAlertSetup(); 
            }
    };

    loadProfilesPage = (event) => {
        event.preventDefault();

        if (localStorage.getItem('SubscriptionStatus') === "active")
        {
            this.setAsActive(event.currentTarget, 'profiles');
            this.setState({ currentPage: 'profiles' });
            localStorage.setItem('CurrentPage','profiles');

            //We don't need the investment type or profile dropdowns for this screen because it is a general screen
            document.getElementById("profileSelect").parentElement.style.display = "none";
            document.getElementById("investmentTypeSelect").parentElement.style.display = "none";
            document.getElementById("propertyCounts").style.display = "none";

            this.enforceAlertSetup(); 
        }
    };

    loadAlertsPage = (event) => {
        
        event.preventDefault();
       
        if (localStorage.getItem('SubscriptionStatus') === "active")
        {
            this.setAsActive(event.currentTarget, 'alerts');
            this.setState({ currentPage: 'alerts' });
            localStorage.setItem('CurrentPage','alerts');

            document.getElementById("profileSelect").parentElement.style.display = "";

            //We don't need the investment type dropdowns for this screen because it is a general screen
            document.getElementById("investmentTypeSelect").parentElement.style.display = "none";
            document.getElementById("propertyCounts").style.display = "none";
        }
    };

    loadMapPage = (event) => {
        event.preventDefault();

        if (localStorage.getItem('SubscriptionStatus') === "active")
        {
            this.setAsActive(event.currentTarget, 'map');
            this.setState({ currentPage: 'map' });
            localStorage.setItem('CurrentPage','map');

            document.getElementById("profileSelect").parentElement.style.display = "";
            document.getElementById("investmentTypeSelect").parentElement.style.display = "";
            document.getElementById("propertyCounts").style.display = "";

            this.enforceAlertSetup(); 
        }
    };

    loadSearchPage = (event) => {
        event.preventDefault();

        if (localStorage.getItem('SubscriptionStatus') === "active")
        {
            this.setAsActive(event.currentTarget, 'search');
            this.setState({ currentPage: 'search' });
            localStorage.setItem('CurrentPage','search');

            document.getElementById("profileSelect").parentElement.style.display = "";
            document.getElementById("investmentTypeSelect").parentElement.style.display = "";
            document.getElementById("propertyCounts").style.display = "";

            this.enforceAlertSetup(); 
        }
    };

    loadChartsPage = (event) => {
        event.preventDefault();

        if (localStorage.getItem('SubscriptionStatus') === "active")
        {
            this.setAsActive(event.currentTarget, 'charts');
            this.setState({ currentPage: 'charts' });
            localStorage.setItem('CurrentPage','charts');

            document.getElementById("profileSelect").parentElement.style.display = "";
            document.getElementById("investmentTypeSelect").parentElement.style.display = "";
            document.getElementById("propertyCounts").style.display = "";

            this.enforceAlertSetup(); 
        }
    };

    loadAccountPage = (event) => {
        event.preventDefault();
        this.setAsActive(event.currentTarget, 'account');
        this.setState({ currentPage: 'account' });
        localStorage.setItem('CurrentPage','account');

        document.getElementById("profileSelect").parentElement.style.display = "";
        document.getElementById("investmentTypeSelect").parentElement.style.display = "";
        document.getElementById("propertyCounts").style.display = "";
    };

    loadSettingsPage = (event) => {
        event.preventDefault();

        if (localStorage.getItem('SubscriptionStatus') === "active")
        {
            this.setAsActive(event.currentTarget, 'settings');
            this.setState({ currentPage: 'settings' });
            localStorage.setItem('CurrentPage','settings');

            //We don't need the investment type or profile dropdowns for this screen because it is a general screen
            document.getElementById("profileSelect").parentElement.style.display = "none";
            document.getElementById("investmentTypeSelect").parentElement.style.display = "none";
            document.getElementById("propertyCounts").style.display = "none";

            this.enforceAlertSetup(); 
        }
    };

    goToAccountPage = (event) => {
       
        document.body.click(); //This to force the popover to close
        this.loadAccountPage(event);        

    };


    showPrimaryHomes = (event) => {  
        const investmentType = "Primary Home";     
        this.setState({investmentType: investmentType});  
        localStorage.setItem('SelectedInvestmentType',investmentType);
    }

    showRentalProperties = (event) => {  
        const investmentType = "Rental Property";     
        this.setState({investmentType: investmentType});  
        localStorage.setItem('SelectedInvestmentType',investmentType);
    }

    showFlippingProperties = (event) => {  
        const investmentType = "Flipping Property";     
        this.setState({investmentType: investmentType});  
        localStorage.setItem('SelectedInvestmentType',investmentType);
    }

    handleInvestmentTypeChange = (event) => {     
        
        this.setState({investmentType: event.target.value});  
        localStorage.setItem('SelectedInvestmentType',event.target.value);
     
    }

    handleProfileChange = (event) => {     
  
        const profile = this.state?.profiles?.filter(profile => profile.id == event.target.value)[0];
        this.setState({
                        profileId: event.target.value,
                        profile: profile
                    });  

        localStorage.setItem('SelectedProfileId',event.target.value);
     
    }

    logoutUser = (event) => {     

        localStorage.removeItem('AuthToken');
        localStorage.removeItem('RefreshToken');
		window.location.href = '/login';
    }

    preselectInvestmentTypeSelect()
    {
        let investmentType = this.state.investmentType;
        
        var defaultOption = null;

        var investmentTypeOptions = document.getElementById("investmentTypeSelect").options;
        
        for (let i = 0; i < investmentTypeOptions.length; i++) {
          
            defaultOption = investmentTypeOptions[i];
            
            if (defaultOption.value.startsWith("Primary"))
            {
                break;
            }
            
        }

        if (localStorage.getItem('SelectedInvestmentType') != null && localStorage.getItem('SelectedInvestmentType')!='') // Preselect it based on user previously selected
        {
            investmentType = localStorage.getItem('SelectedInvestmentType');    
                    
            for (let i = 0; i < investmentTypeOptions.length; i++) {
                //console.log(investmentTypeOptions[i].text);
                //console.log(investmentTypeOptions[i].value);
        
                if (investmentTypeOptions[i].value == investmentType)
                {
                    investmentTypeOptions[i].selected = true;
                    this.setState({investmentType: investmentTypeOptions[i].value});  
                    localStorage.setItem('SelectedInvestmentType',investmentTypeOptions[i].value);
                    break;
    
                }
            }

        } 

     //   if (investmentType == null || investmentType == '' )
     //   {
     //       defaultOption.selected = true;
     //       this.setState({investmentType: defaultOption.value}); 
     //       localStorage.setItem('SelectedInvestmentType',defaultOption.value); 

     //   }

    }

    preselectProfileSelect(profiles)
    {
        let profileId = this.state.profileId;
        
        if (localStorage.getItem('SelectedProfileId') != null && localStorage.getItem('SelectedProfileId')!='')
        {
            profileId = localStorage.getItem('SelectedProfileId');            	
        }

        var profileOptions = document.getElementById("profileSelect").options;
       
        var defaultOption = null;
        //Get default option
        for (let i = 0; i < profileOptions.length; i++) {
        //console.log(profileOptions[i].text);
        //console.log(profileOptions[i].value);

            if (profileOptions[i].text.startsWith("Default"))
            {
                defaultOption = profileOptions[i];
                break;
            }
        }

        //In the case it is a trial version use the Demo as default
        if (this.state.user != null && this.state.user.planType != null  && this.state.user.planType === "trial")
        {
            for (let i = 0; i < profileOptions.length; i++) {
                
                    if (profileOptions[i].text.startsWith("Demo"))
                    {
                        defaultOption = profileOptions[i];
                        break;
                    }
                }
        }
        


        if (profileId == null || profileId == '' || this.state.profile == null)
        {
            defaultOption.selected = true;
            
            const profile = this.state?.profiles?.filter(profile => profile.id == defaultOption.value)[0];
            this.setState({
                        profileId: defaultOption.value,
                        profile: profile
                    }, () => {
                        
                      });


            localStorage.setItem('SelectedProfileId',defaultOption.value); 

        } else
        {
            var foundIt = false;
            for (let i = 0; i < profileOptions.length; i++) {
                //console.log(profileOptions[i].text);
                //console.log(profileOptions[i].value);
        
                if (profileOptions[i].value == profileId)
                {
                    profileOptions[i].selected = true;

                    const profile = this.state?.profiles?.filter(profile => profile.id == profileOptions[i].value)[0];
                    this.setState({
                        profileId: profileOptions[i].value,
                        profile: profile
                    });  

                    localStorage.setItem('SelectedProfileId',profileOptions[i].value);
                    foundIt = true;
                    break;
    
                }
            }

            if (foundIt == false)
            {
                defaultOption.selected = true;
                const profile = this.state?.profiles?.filter(profile => profile.id == defaultOption.value)[0];
                this.setState({
                        profileId: defaultOption.value,
                        profile: profile
                    });  
  
                localStorage.setItem('SelectedProfileId',defaultOption.value);
            }
        }

    }


    setAsActive(element,type) {

        element = element.parentElement;
        
        //alert(element.innerHTML);

        var elements = ReactDOM.findDOMNode(this).getElementsByClassName('nav-item');

        if (elements != null) {
            for (var i in elements) {
                if (elements[i].classList != null) {
                    elements[i].classList.remove("active");
                  //  elements[i].classList.remove("first-nav-item");
                }
            }
        }

        element.classList.add("active");

        /*
        if (type == 'dashboard') {
            element.classList.add("first-nav-item");
        }
        */

        //alert(element.classList);
    }


    render() {
        const page = () => {

            this.checkUserSubscriptionStatus();

            switch (this.state.currentPage) {

                //case "dashboard": return <Dashboard investmentType = {this.state.investmentType} profileId = {this.state.profileId} settings = {this.state.settings} profile= {this.state.profile} user= {this.state.user}/>;
                //case "properties": return <Properties investmentType = {this.state.investmentType} profileId = {this.state.profileId} settings = {this.state.settings} profile= {this.state.profile} user= {this.state.user}/>;
                
                case "dashboard": return <PropertyView viewMode="dashboard" investmentType = {this.state.investmentType} profileId = {this.state.profileId} settings = {this.state.settings} profile= {this.state.profile} user= {this.state.user} parentRef={this}/>;
                case "properties": return <PropertyView viewMode="properties" investmentType = {this.state.investmentType} profileId = {this.state.profileId} settings = {this.state.settings} profile= {this.state.profile} user= {this.state.user} parentRef={this}/>;
                
                case "map": return <PropertiesMap investmentType = {this.state.investmentType} profileId = {this.state.profileId} settings = {this.state.settings} profile= {this.state.profile} user= {this.state.user}/>;
                case "alerts": return <Alerts profileId = {this.state.profileId} profile= {this.state.profile} user= {this.state.user}  properties= {this.state.properties} parentRef={this}/>;
                case "charts": return <Charts investmentType = {this.state.investmentType} profileId = {this.state.profileId} settings = {this.state.settings} profile= {this.state.profile} user= {this.state.user}/>;
                //case "search": return <Search />;
                case "profiles": return <Profiles  user= {this.state.user}/>;
                case "account": return <Account  user= {this.state.user}/>;
                case "settings": return <Settings  user= {this.state.user} settings = {this.state.settings} parentRef={this}/>;

                default: return <PropertyView viewMode="dashboard" investmentType = {this.state.investmentType} profileId = {this.state.profileId} settings = {this.state.settings} profile= {this.state.profile} user= {this.state.user} parentRef={this}/>;
            }
        }


        return (

            <body id="page-top">

              {this.state.displayScreen &&   
              <div>
                {/* Page Wrapper */}
                <div id="wrapper">

                    <div class="left-nav-borders"> {/* Only for desktop */}
                    <ul class="navbar-nav bg-gradient-primary sidebar sidebar-dark accordion" id="accordionSidebar" style={{maxWidth:"300px",height:"100%"}} styleX={{width:"50px"}}> {/* this style fixed the mobile side menu width issue*/}

                        <a class="sidebar-brand d-flex align-items-center justify-content-center dash-logo-link" href="/">
                            <img src={logo} class="dashboard-logo" alt="Real Estate Property Analytics" />
                        </a>
                        <div style={{paddingTop:'20px',textAlign:'center',color:'#a0a0a0'}}>Real Estate Property Analytics</div>

                        {/* Just to add space */}
                        <div style={{maxWidth:"300px",paddingTop:'25px'}}>
                            &nbsp;
                        </div>

                        {/* Sidebar */}
                        {/*
                        <li class="nav-item first-nav-item active">
                            <a class="nav-link" id="dashboard" href="#" onClick={this.loadDashboardPage}>
                                    <img src={dashboardicon} alt="" class="first-image"/>
                                <div>Dashboard</div>
                            </a>
                        </li>
                        */}


                       {/* <li class="nav-item first-nav-item active"> */}
                       <li class="nav-item active">
                            <a class="nav-link" id="dashboard" href="#" onClick={this.loadDashboardPage}>
                                {/* <span class="img-side-logo"> */}
                                <img src={propertiesicon} alt="" style={{height:"30px"}}/>
                                {/*   </span> */}
                                <div style={{paddingBottom:'8px'}}>Properties <span style={{float:"right",minWidth:"15px",textAlign:"center",marginTop:"5px",marginLeft:"40px",marginBottom:"2px",paddingRight:'3px',paddingLeft:'3px',fontWeight:"bold",fontSize:"12px", borderRadius:"3px 3px 3px 3px",backgroundColor:"#f4b619"}}>{this.state?.propertyCounts?.primaryHomePropertyCount+this.state?.propertyCounts?.rentalPropertyCount+this.state?.propertyCounts?.flippingPropertyCount}</span>  </div>
                            </a>
                        </li>

                        <li class="nav-item">
                            <a class="nav-link" id="charts" href="#" onClick={this.loadMapPage}>
                                <img src={mapicon} alt="" style={{height:"30px"}}/>
                                <div>Heatmap</div>
                            </a>
                        </li>

                        <li class="nav-item">
                            <a class="nav-link" id="charts" href="#" onClick={this.loadChartsPage}>
                                <img src={metricsicon} alt="" style={{height:"30px"}}/>
                                <div>Metrics</div>
                            </a>
                        </li>
                        
                        <li class="nav-item">
                            <a class="nav-link" id="alerts" href="#" onClick={this.loadAlertsPage}>
                                <img src={searchicon} alt="" style={{height:"30px"}}/>
                                <div>Advanced Search </div>
                            </a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" id="profiles" href="#" onClick={this.loadProfilesPage}>
                                <img src={profilesicon} alt=""  style={{height:"30px"}}/>
                                <div>Profiles</div>
                            </a>
                        </li>


                        <li class="nav-item">
                            <a class="nav-link" id="settings" href="#" onClick={this.loadSettingsPage}>
                                <img src={settingsicon} alt=""  style={{height:"30px"}}/>
                                <div>Settings</div>
                            </a>
                        </li>

                        <li class="nav-item">
                            <a class="nav-link" id="account" href="#" onClick={this.loadAccountPage}>
                                <img src={accounticon}  alt="" style={{height:"30px"}}/>
                                <div>My Account</div>
                            </a>
                        </li>
                    </ul>


                    </div>
                    {/* End of Sidebar */}

                    {/* Content Wrapper */}
                    <div id="content-wrapper" class="d-flex flex-column">

                        {/* Main Content */}
                        <div id="content" class="bg-black">

                            {/* Topbar */}
                            <nav class="navbar navbar-expand navbar-light bg-gray topbar mb-4 static-top" style={{height:"90px",marginBottom:'0',paddingBottom:'0'}}>
                                
                                <button id="sidebarToggleTop" class="btn btn-link d-md-none rounded-circle mr-3"> <i class="fa fa-bars"></i> </button>
                                                                                   
                                    {/* Desktop only */}     
                                    {/*
                                    <div class="input-group region-group desktop-only-block" style={{paddingRight:"5px",paddingTop:'20px'}}>

                                        <div class="input-group-prepend"> <span class="input-group-text" id="basic-addon1">Profile</span> </div> 
                                        <select class="form-control" id="profileSelect" value={this.state.profileId} onChange={this.handleProfileChange}>
                                            {this.state.profiles.map((profile, i) => ( 
                                                <option value={profile.id}>{profile.profileName}</option>
                                        
                                            ))}
                                        </select>

                                    </div>

                                    */}

                                    <div class="input-group region-group desktop-only-block" style={{paddingRight:"5px"}}>


                                    <div class="input-group region-group desktop-only-block" style={{paddingRight:"5px",paddingTop:'20px'}}>

                                    <div class="input-group-prepend"> <span class="input-group-text" id="basic-addon1">Profile</span> </div> 
                                        <select class="form-control" id="profileSelect" value={this.state.profileId} onChange={this.handleProfileChange}>
                                            {this.state.profiles.map((profile, i) => ( 
                                                <option value={profile.id}>{profile.profileName}</option>
                                        
                                            ))}
                                        </select>
                                    </div>

                                    <div class="desktop-only-block" style={{fontSize:'13px',fontWeight:'bold',paddingTop:'7px',paddingBottom:'7px'}}>&nbsp;</div> {/* Filler */}
                                    </div>


                                    {/* Desktop only */}            
                                    <div class="input-group region-group desktop-only-block" style={{paddingRight:"5px"}}>

                                    {/*            
                                    <span style={{fontSize:'13px',color:'#000000',fontWeight:'bold'}}>Primary Homes:  &nbsp;<span style={{color: '#0059a6',textDecoration: 'underline',fontWeight:'bold',fontSize:'15px',cursor: 'pointer'}} onClick={this.showPrimaryHomes}>{this.state?.propertyCounts?.primaryHomePropertyCount}</span> &nbsp;&nbsp;&nbsp; Flipping Properties: <span style={{color: '#0059a6',textDecoration: 'underline',fontWeight:'bold',fontSize:'15px',cursor: 'pointer'}} onClick={this.showFlippingProperties}>{this.state?.propertyCounts?.flippingPropertyCount}</span> &nbsp;&nbsp;&nbsp; Rental Properties: <span style={{color: '#0059a6',textDecoration: 'underline',fontWeight:'bold',fontSize:'15px',cursor: 'pointer'}} onClick={this.showRentalProperties}>{this.state?.propertyCounts?.rentalPropertyCount}</span></span>

                                        <div class="input-group-prepend"> <span class="input-group-text investment-type-dropdown-label" id="basic-addon1">Investment Type</span> </div> 
                                        <select class="form-control" id="investmentTypeSelect" value={this.state.investmentType} onChange={this.handleInvestmentTypeChange}>
                                            <option value="Primary Home">Primary Homes</option>
                                            <option value="Flipping Property">Flipping Properties</option>
                                            <option value="Rental Property">Rental Properties</option>
                                        </select>
                                    */}

                                    <div class="input-group region-group desktop-only-block" style={{paddingRight:"5px",paddingTop:'20px'}}>

                                    <div class="input-group-prepend"> <span class="input-group-text" id="basic-addon1">Investment Type</span> </div> 
                                    <select class="form-control" id="investmentTypeSelect" value={this.state.investmentType} onChange={this.handleInvestmentTypeChange}>
                                            <option value="Primary Home">Primary Homes</option>
                                            <option value="Flipping Property">Flipping Properties</option>
                                            <option value="Rental Property">Rental Properties</option>
                                        </select>
                                    </div>

                                    <div  class="desktop-only-block" id="propertyCounts" style={{fontSize:'13px',color:'#b7b7b7',fontWeight:'bold',paddingTop:'7px',paddingBottom:'7px'}}>Primary Homes:  &nbsp;<span style={{color: '#5a99f7',textDecoration: 'underline',fontWeight:'bold',fontSize:'15px',cursor: 'pointer'}} onClick={this.showPrimaryHomes}>{this.state?.propertyCounts?.primaryHomePropertyCount}</span> &nbsp;&nbsp;&nbsp; Flipping Properties: <span style={{color: '#5a99f7',textDecoration: 'underline',fontWeight:'bold',fontSize:'15px',cursor: 'pointer'}} onClick={this.showFlippingProperties}>{this.state?.propertyCounts?.flippingPropertyCount}</span> &nbsp;&nbsp;&nbsp; Rental Properties: <span style={{color: '#5a99f7',textDecoration: 'underline',fontWeight:'bold',fontSize:'15px',cursor: 'pointer'}} onClick={this.showRentalProperties}>{this.state?.propertyCounts?.rentalPropertyCount}</span></div>
                                    </div>
                                                
                                    {/* Mobile Only */}
                                    <div class="mobile-only-block" style={{width:"80%",minWidth:"80%"}}>

                                    <table  style={{width:"100%",minWidth:"100%"}}>
                                        <tr>
                                            <td>
                                            <div class="input-group region-group" style={{paddingRight:"5px",width:"100%",minWidth:"100%"}}>
                                                    <select class="form-control" id="profileSelect" value={this.state.profileId} onChange={this.handleProfileChange}>
                                                    {this.state.profiles.map((profile, i) => ( 
                                                        <option value={profile.id}>{profile.profileName}</option>
                                                
                                                    ))}
                                                    </select>
                                               </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <div class="input-group region-group" style={{paddingRight:"5px",width:"100%",minWidth:"100%"}}>
                                                    <select class="form-control" id="investmentTypeSelect" value={this.state.investmentType} onChange={this.handleInvestmentTypeChange} style={{minWidth:"100%"}}>
                                                        <option value="Rental Property">Rental Properties</option>
                                                        <option value="Primary Home">Primary Homes</option>
                                                        <option value="Flipping Property">Flipping Properties</option>
                                                    </select>
                                                </div>
                                            </td>
                                        </tr>

                                    </table>
                               

                                    </div> 
                                    {/* End Mobile */}


                                <ul class="navbar-nav ml-auto">

                                  {this.state?.user?.planType === "trial" &&                       
                                    <li class="user-list desktop-only-block">
                                        <div style={{paddingRight:'10px'}}>
                                            <Button onClick={this.goToUpGrade}>Upgrade</Button>
                                        </div>
                                    </li>
                                  }

                                    {/* Account Info */}
                                    <li class="user-list">
                                    <OverlayTrigger 
                                        rootClose 
                                        placement="bottom" 
                                        delay={{ show: 150, hide: 100 }} 
                                        trigger={["click"]}
                                        overlay={<Tooltip  className="messagetooltipWhite">
                                    
                                        <div className='helpText'>
                                        
                                        <center>
                                            <span style={{fontWeight:'normal'}}>{this.state.sessionUserName}</span> 
                                        </center>

                                        <div style={{paddingTop:'15px',paddingBottom:'15px'}}>
                                            <button class="button-link-not-blue" type="button" data-dismiss="modal" onClick={this.goToAccountPage}><b>MY ACCOUNT</b></button>
                                        </div>     
                                        
                                        <div style={{paddingBottom:'5px'}}>      
                                            <button class="button-link-not-blue" type="button" data-dismiss="modal" onClick={this.logoutUser}><b>LOGOUT</b></button>
                                        </div>

                                        </div>
                                        </Tooltip>}
                                                                        >

                                         <div style={{paddingRight:'10px'}}>      
                                            <span class="person-img-user"><i class="fas fa-user-circle"></i></span> 
                                         </div>
                                         </OverlayTrigger>
                                    </li>

                             
                                </ul>

                            </nav>
                            
                            <div class="desktop-only-block" style={{height:"90px",borderBottom: '1px solid #595959',maxHeight:"1px",padding:'0',marginTop:'0',marginLeft:'10px',marginRight:'10px',marginBottom:'15px'}}>&nbsp;</div>

                            <div class="container desktop-container">
                                {page()}
                            </div>

                        </div>
                        <span id="mobile-check-element" style={{maxHeight:'1px',backgroundColor:'#000000'}}>&nbsp;</span>
                        
                        <div class="footer">
                            <div class="container">
                                <div class="row">
                                    <div class="col-md-12">
                                        <div class="footer-links">
                                            <a href="/terms-of-service.html" target="_blank">Terms & Conditions </a>
                                            <a href="/privacy.html" target="_blank">Privacy Policy </a>
                                            <a href="/contactus" target="_blank">Contact Us </a>
                                        </div>
                                        <br></br>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>

                <a class="scroll-to-top rounded" href="#page-top">
                    <i class="fas fa-angle-up"></i>
                </a>

                <Dialog ref={(component) => { this.dialogMessage = component }}/>   

                <Dialog ref={(component) => { this.noAlertDialogMessage = component }}/>     

                <InvestorTypeDialog ref={this.investorTypeDialogRef}></InvestorTypeDialog>

                <FinancingPreferenceDialog ref={this.financingPreferenceDialogRef}></FinancingPreferenceDialog>

                <Helmet>
                    <script src="vendor/jquery/jquery.min.js" type="text/javascript" /> 
                    <script src="js/sb-admin-2.min.js" type="text/javascript" /> 
                    <script src="vendor/bootstrap/bootstrap.bundle.min.js" type="text/javascript" /> 
                </Helmet>
        

            </div>
            }
            </body>

        );
    }
}

export default (Home);
